import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import PrivacyPagesHandler from "../components/privacyPages/PrivacyPagesHandler"
import { replaceStringVars } from "../utils/utils"

const PrivacyPages = ({ data, pageContext, location }) => {
  let post
  let language

  if (pageContext && pageContext.language) {
    language = pageContext.language

    post = data.allPrivacyPagesJson.nodes[0]
  } else {
    post = data.allPrivacyPagesJson.nodes[0]
  }

  const stringVariables = {
    PRACTICE_NAME: data.siteData.siteMetadata.fullPracticeName
  }

  const metaTitle = replaceStringVars(post.metaTitle, stringVariables)
  const metaDescription = replaceStringVars(
    post.metaDescription,
    stringVariables
  )

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={metaTitle}
          description={metaDescription}
          pathname={location.pathname}
          lang={language}
        />

        <div className="columns joshua-tree-content privacy-policy">
          <div className="column is-2" />
          <div className="column">
            <PrivacyPagesHandler
              pageType={post.name}
              locations={data.locations.nodes}
              practiceName={data.siteData.siteMetadata.fullPracticeName}
              practiceUrl={data.siteData.siteMetadata.siteUrl}
              {...post}
            />
          </div>
          <div className="column is-2" />
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default PrivacyPages

export const pageQuery = graphql`
  query ($title: String!) {
    allPrivacyPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        documentUpdatedOn
        body
        manual_file
      }
    }

    locations: allLocationsJson(
      sort: { fields: locationData___order }
      filter: { useOnContact: { eq: true } }
    ) {
      nodes {
        locationData {
          cityName
          cityZip
          email
          locationName
          stateAbbr
          streetName
          streetNumber
          stateName
        }
      }
    }

    siteData: site {
      siteMetadata {
        fullPracticeName
        siteUrl
      }
    }
  }
`
